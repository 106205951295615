import styled, { themeGet, device } from "@styled";
import quote from "@data/images/bg/quote-left.png";
export const TestimonialWrap = styled.div`
  position: relative;
`;

export const TestimonialInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const TestimonialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  border-radius: ${themeGet("radii.round")};
  box-shadow: ${themeGet("shadows.sm")};
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${themeGet("radii.round")};
  }
  &::before {
    background: linear-gradient(
      320deg,
      ${themeGet("colors.secondaryDark")},
      ${themeGet("colors.secondary")}
    );
  }
  &::after {
    background: url(${quote});
    background-repeat: no-repeat;
    background-position: center;
  }
  ${device.small} {
    margin: 0 auto;
  }
`;

export const Review = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;
export const TestimonialSubject = styled.h6`
  font-size: 16px;
  padding-top: 20px;
  text-align: center;
  color: ${themeGet("colors.primary")};
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 158px;
    height: 2px;
    background: ${themeGet("colors.primary")};
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
`;
export const ClientProfession = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  text-align:center;
  margin:14px 0 0;
`