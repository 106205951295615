import styled, { themeGet, device } from "@styled";

export const FunFactWrap = styled.div`
  text-align: center;
  min-height:114px;
  display:grid;
  align-items:center;
  box-shadow: ${themeGet("shadows.custom")};
  background: #fff;
  border-radius: 17px;
  padding: 30px;
  transition: ${themeGet("transition")};
  ${device.xlarge} {
    &:nth-child(3),
    &:nth-child(4) {
      transform: translateX(calc(60% - 50px));
    }
  }
  ${device.xxlarge} {
    &:nth-child(3),
    &:nth-child(4) {
      transform: translateX(calc(100% - 50px));
    }
  }
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
`;

export const FunFactTitle = styled.h5`
  color: ${themeGet("colors.heading")};
  font-weight: 500;
  margin-block-end: 10px;
`;

export const FunFactCount = styled.div`
  font-size: clamp(34px, 5vw, 56px);
  font-weight: 400;
  line-height: 1;
  color: ${themeGet("colors.primary")};
  span {
    font-size: 25px;
    font-weight: bold;
    position: relative;
    margin-right: 4px;
  }
`;

export const FunFactText = styled.h6`
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${themeGet("colors.gray")};
  font-weight: normal;
  margin-top:10px;
`;
