import React from "react";
import styled, { themeGet, device, color } from "@styled";

export const SectionWrap = styled(({ bg, ...rest }) => <section {...rest} />)`
  padding-block-start: 60px;
  padding-block-end: 25px;
  background: #f8f8f8;
  ${device.medium} {
    padding-block-start: 80px;
    padding-block-end: 45px;
  }
  ${device.large} {
    padding-block-start: 120px;
    padding-block-end: 105px;
  }
  ${color};
`;

export const HeaderWrap = styled.div`
  margin-block-end: 20px;
  ${device.medium} {
    margin-inline-end: 3rem;
    margin-block-end: 0;
  }
`;

export const StyledLeftHeading = styled.h4`
  font-size: 24px;
  span {
    color: ${themeGet("colors.primary")};
  }
  ${device.medium} {
    font-size: 28px;
  }
  ${device.large} {
    font-size: 38px;
  }
`;
export const CounterBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap:8px 14px;
  .fun-fact{
   width: 100%;
   padding:10px 10px;
  }
  .fun-fact:nth-child(3), .fun-fact:nth-child(4) {
    transform: none;
}
.counterBoxWrapper{
  filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.07));
  background-color: #ffffff;
}
.fun-fact:first-child {
  ${device.small} {
    grid-column: 2 span;
    width: calc(50% - 7px);
    margin-left: auto;
  }
  ${device.xlarge} {
    grid-column: 3 span;
    width: calc(33.3% - 7px);
  }
}
${device.small} {
  grid-template-columns: repeat(2, 50%);
}
${device.xlarge} {
  grid-template-columns: repeat(3, 33.33%);
}
`;
