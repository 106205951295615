import styled, { device } from "@styled";

export const MembershipsWrapper = styled.section`
  padding: 90px 0;
`;
export const MembershipsInner = styled.div``;
export const MembershipLogoWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  margin-top: 30px;
  ${device.large} {
    margin-top: 0;
  }
  ${device.xlarge} {
    grid-gap: 25px;
  }
  ${device.xxlarge} {
    grid-gap: 40px;
  }
`;
export const MembershipLogo = styled.div`
  padding: 25px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
