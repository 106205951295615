import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import {
  MembershipsWrapper,
  MembershipsInner,
  MembershipLogoWrap,
  MembershipLogo,
} from "./style";

export default function Memberships({ data }) {
  return (
    <MembershipsWrapper>
      <MembershipsInner>
        <Container>
          <Row>
            <Col lg={4}>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
              />
            </Col>
            <Col lg={8}>
              <MembershipLogoWrap>
                {data &&
                  data.items.map((el, index) => (
                    <MembershipLogo key={index}>
                      <Image
                        src={el.images[0].src}
                        alt={el.images[0]?.alt || "Logo"}
                      />
                    </MembershipLogo>
                  ))}
              </MembershipLogoWrap>
            </Col>
          </Row>
        </Container>
      </MembershipsInner>
    </MembershipsWrapper>
  );
}
