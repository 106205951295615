import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
} from "./style";

const BoxSection = ({ data, layout }) => {
  return (
    <BoxSectionWrapper layout={layout} id={data.section}>
      <BoxSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap>
                {data &&
                  data.items.map((el, index) => (
                    <BoxSectionWrapInner key={index}>
                      {el?.images[0]?.src && <BoxSectionIconwrap>
                          <Image
                            src={el?.images[0]?.src}
                            alt={el?.images[0]?.alt || "Icon"}
                          />
                        </BoxSectionIconwrap> }

                        <BoxSectionTextWrap>
                          <Heading as="h6" className="text-white">
                            {el.subject}
                          </Heading>
                          <Heading as="h4" className="text-white custHght">
                            {el.title}
                          </Heading>
                          <Text>{el.description}</Text>
                        </BoxSectionTextWrap>
                        {( !! el.path ?
                          <Anchor path={el.path} key={index}>Learn more &rsaquo;</Anchor> : ""
                        )}
                      </BoxSectionWrapInner>
                  ))}
              </BoxSectionWrap>
            </Col>
          </Row>

        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  );
};

BoxSection.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  items: PropTypes.object,
};
BoxSection.defaultProps = {
  layout: 1,
};

export default BoxSection;
