import styled, {css, device, themeGet } from "@styled";
import testimonialBG from "@data/images/bg/testimonials-quoteBG.png";

export const TestimonialWrappper = styled.section`
padding:120px 0;
position relative;
&::before {
  content: '';
  position: absolute;
  width: 184px;
  height: 156px;
  opacity: 0.05;
  background: url(${testimonialBG});
  left: 100px;
  top: 180px;
}
${(props) =>
    props.layout === 2 &&
    css`
      background-image: linear-gradient(-33deg, #15054f 0%, #561783 99%, #561783 100%);
      .section-title{
      h2{
      color:#fff
      }
      }
    `}
    ${device.medium} {
        padding-block: 50px;
        padding-inline: 25px;
    }
    ${device.large} {
        padding-block: 60px;
        padding-inline: 65px;
    }
    ${(props) =>
        props.layout === 1 &&
        css`
        padding:60px 0 60px;
         ${device.large} {
           padding:90px 0 60px;
        }
        `}
    .swiper {
        padding: 30px 0;
        &-wrapper{
            align-items: center;
            ${device.medium} {
                padding: 15px;
            }
        }
        &-slide {
            background: #fff;
            border-radius: 6px;
            padding: 10px;
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
             ${(props) => 
                    props.layout === 2 &&
                    css`
                      background-color: #54288b;
                      color:#fff !important;
                    `}
            transition: ${themeGet("transition")};
            ${device.medium} {
                transform: scale(0.9);
            }
            ${device.xlarge} {
                padding: 20px;
            }
            &-prev, &-next {
                box-shadow: ${themeGet("shadows.sm")};
            }
            &-active {
                box-shadow: ${themeGet("shadows.sm")};
                z-index: 9;                
                ${device.medium} {
                    transform: scale(1.1);
                }
            }
        }
    }
`;
