import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Counter from "@components/counter/layout-04";
import MarkHeading from "@components/mark-heading";
import { HeadingType, ItemType } from "@utils/types";
import {
  SectionWrap,
  HeaderWrap,
  StyledLeftHeading,
  CounterBox,
} from "./style";

const FunFactArea = ({ data, ...rest }) => {
  return (
    <SectionWrap {...rest}>
      <Container>
        <Row>
          <Col lg={5} md={4} className="d-flex align-items-center">
            <HeaderWrap>
              {data?.headings?.[0] && (
                <MarkHeading
                  mb="24px"
                  maxWidth={["100%", null, null, "330px"]}
                  as={data.headings[0]?.level}
                >
                  {data.headings[0]?.content}
                </MarkHeading>
              )}
              {data?.headings?.[1] && (
                <StyledLeftHeading
                  as={data.headings[1]?.level}
                  dangerouslySetInnerHTML={{
                    __html: data.headings[1].content,
                  }}
                />
              )}
            </HeaderWrap>
          </Col>
          <Col lg={7} md={8}>
            <Row>
              <Col>
                <CounterBox>
                  {data?.items?.map((item) => (
                    <Counter
                      text={item.title}
                      countTo={item.count}
                      key={item.id} 
                      className="counterBoxWrapper"
                    />
                  ))}
                </CounterBox>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

FunFactArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default FunFactArea;
