import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import {
  CompanyreviewWrapper,
  CompanyreviewInner,
  CompanyContant,
  CompanyLogo,
  CompanyContantInner,
} from "./style";
// import Image from "@ui/image";
const Text = React.lazy(() => import('@ui/text'));
const Image = React.lazy(() => import('@ui/image'));
const Heading = React.lazy(() => import('@ui/heading'));
const Ratings = React.lazy(() => import('@components/ratings'));
const Anchor = React.lazy(() => import('@ui/anchor'));
const SectionTitle = React.lazy(() => import('@ui/section-title'));

const CompanyReviews =  React.memo(({ data, rating, layout, ...props }) =>{
  return (
    typeof window !== 'undefined' && (
      <React.Suspense fallback={<div></div>}>
    <CompanyreviewWrapper layout={layout} {...props}>
      <CompanyreviewInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CompanyContant>
                {data?.items?.length &&
                  data?.items?.map((el, index) => (
                    <CompanyContantInner key={index} className={el.description}>
                      <CompanyLogo>
                        <Image
                          src={el?.images[0]?.src}
                          alt={el?.images[0]?.alt || "Icon"}
                        />
                      </CompanyLogo>
                      <Heading as="h4" dangerouslySetInnerHTML={{ __html: el?.title }} ></Heading>
                      <Text>{el?.texts[0]?.content}</Text>
                      {el && el.period && (
                        <Ratings rating={parseFloat(el.period)} />
                      )}
                      {(!! el?.path?
                        <Anchor key={index} path={el?.path} target="_blank" className="stretched-link"></Anchor> : ""
                      )}
                      {((el?.images.length>1 && el?.images[1]?.src) ?
                        <Image
                          src={el?.images[1]?.src}
                          alt={el?.images[1]?.alt || "Icon"}
                        />
                        : "")}
                      {/* {(el?.bottomImage[0]?.src ?
                        <Image
                          src={el?.bottomImage[0]?.src}
                          alt={el?.bottomImage[0]?.alt || "Icon"}
                        />
                        : "")}  */}
                    </CompanyContantInner>
                  ))}
              </CompanyContant>
            </Col>
          </Row>
        </Container>
      </CompanyreviewInner>
    </CompanyreviewWrapper>
    </React.Suspense>
)
  );
})
CompanyReviews.propTypes = {
  layout: PropTypes.oneOf([1, 2]),
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
CompanyReviews.defaultProps = {
  layout: 1,
};
export default CompanyReviews;